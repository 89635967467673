@layer utilities {

  // ---------------------- Headings ------------------------
  .h1 {
    @apply text-6xl ipad:text-8xl ipad:mb-32 mb-24 text-grey-600;
  }

  .h2 {
    @apply text-5xl ipad:text-7xl ipad:mb-32 mb-24 text-grey-600;
  }

  .h3 {
    @apply text-3xl ipad:text-4xl ipad:mb-32 mb-24 text-grey-600;
  }

  .h4 {
    @apply text-lg ipad:text-2xl ipad:mb-32 mb-24 text-grey-600;
  }

  // -------------------- Text Body ------------------
  .text {
    &-display {
      @apply text-8xl ipad:text-9xl;
    }

    &-body {
      &-xl {
        @apply text-md ipad:text-xl;
      }

      &-lg {
        @apply text-md;
      }

      &-sm {
        @apply text-sm;
      }

      &-xs {
        @apply text-2xs;
      }

      &-xxs {
        @apply text-3xs;
      }
    }

    &-eyebrow {
      @apply text-xs;
    }
  }

  // ------------------- button / links -------------------
  .btn {
    @apply rounded-2 border-2 border-solid px-30 py-16 text-sm font-mazzardSemiBold;

    &-primary {
      @apply border-purple-200 bg-purple-200 text-white hover:border-dark hover:bg-dark;
    }

    &-secondary {
      @apply border-purple-200 bg-white text-purple-200 hover:bg-grey-300;
    }

    &-download {
      @apply bg-grey-500 p-24 text-white hover:text-grey-300;
    }
  }

  .link {
    @apply font-mazzardSemiBold;

    &-primary {
      @apply text-blue-200 hover:text-dark font-mazzardSemiBold;
    }
  }

  .backdrop {
    @apply bg-dark bg-opacity-40 absolute top-0 left-0 w-full h-full z-[1];
  }

  .site-footer p {
    @apply text-body-xs;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .rich-text .checkmark.list-disc {
    list-style-type: none;
    // padding-left: 28px;
    list-style-image: url("../public/icons/check.svg");
  }
}
