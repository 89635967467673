// @import url($open-sans-url);

@font-face {
  font-family: "MazzardHRegular";
  src: url("../fonts/MazzardH-Regular.ttf") format("truetype");
  /* IE9 Compat Modes */
}

@font-face {
  font-family: "MazzardHMedium";
  src: url("../fonts/MazzardH-Medium.otf") format("opentype");
  /* IE9 Compat Modes */
}

@font-face {
  font-family: "MazzardHSemiBold";
  src: url("../fonts/MazzardH-SemiBold.ttf") format("truetype");
  /* IE9 Compat Modes */
}

@font-face {
  font-family: "MazzardHBold";
  src: url("../fonts/MazzardH-Bold.otf") format("opentype");
  /* IE9 Compat Modes */
}

@font-face {
  font-family: "MazzardHBoldItalic";
  src: url("../fonts/MazzardH-BoldItalic.otf") format("opentype");
  /* IE9 Compat Modes */
}
