.cta {
  &.link {
    svg path {
      stroke: theme("colors.blue.200");
    }

    &:hover {
      color: theme("colors.dark");

      svg path {
        stroke: theme("colors.dark");
      }
    }
  }
}
